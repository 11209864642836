import LogoNoText from './svg/pafbet-logo-no-text.svg';
import LogoVertical from './svg/pafbet-logo-vertical.svg';
import LogoVerticalInverted from './svg/pafbet-logo-vertical-inverted.svg';
import LogoHorizontal from './svg/pafbet-logo-horizontal.svg';
import LogoHorizontalInverted from './svg/pafbet-logo-horizontal-inverted.svg';
import type { LogotypeType } from './logo-variants-types';

export const Logos: LogotypeType = {
  Horizontal: LogoHorizontal,
  HorizontalInverse: LogoHorizontalInverted,
  Vertical: LogoVertical,
  VerticalInverse: LogoVerticalInverted,
  Icon: LogoNoText,
  IconInverse: LogoNoText,
};
